"use client";

import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { shouldLoadOpenReplay } from "src/common/utils/should-load-open-replay";

const OpenReplayTracking = dynamic(
  () =>
    import("src/common/components/OpenReplayTracking").then(
      (mod) => mod.OpenReplayTracking,
    ),
  {
    ssr: false,
    loading: () => null,
  },
);

export function OpenReplayTrackingLoader() {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (typeof window === undefined) return;
    setIsEnabled(shouldLoadOpenReplay());
  }, [setIsEnabled]);

  if (isEnabled) {
    return <OpenReplayTracking />;
  } else {
    return null;
  }
}
